import React, { useState, useEffect } from "react";
import { Input, notification, Spin } from "antd";
import {
    EditTwoTone,
    CheckCircleOutlined,
} from '@ant-design/icons';
import useVerifyOTP from "../../api/useVerifyOtp";
import useRequestOTP from "../../api/useRequestOtp";
import { nextFlow } from "../../redux/student/studentSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom';
import useUpdateVerifyStudents from "../../api/useUpdateVerifyStudent";
import CustomPhoneInput from "../../helpers/CustomPhoneInput";
import { isValidPhoneNumber } from 'react-phone-number-input';
import LeftSideBar from "../LeftSideBar";
import '../DataTables.css';

const MobileVerification = () => {
    const [isMobileValid, setIsMobileValid] = useState( true );
    const [isOTPBoxVisible, setIsOTPBoxVisible] = useState( false );
    const [isEditable, setIsEditable] = useState( false );
    const [mobileNumber, setMobileNumber] = useState( "" );
    const [otpValues, setOtpValues] = useState( ["", "", "", ""] );
    const [isCheckIcon, setIsCheckIcon] = useState( false );

    const [isSendOTPClickable, setIsSendOTPClickable] = useState( true );
    const [isVerifyOTPClickable, setIsVerifyOTPClickable] = useState( false );
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { response: updatedResponse, isLoading: updatingStatus, updateVerifyStudents } = useUpdateVerifyStudents();
    const clientConfig = useSelector( state => state.clientConfiguration );

    const studentRollNumber = localStorage.getItem( 'student_roll_number' );
    const [resendClickable, setResendClickable] = useState( false );
    const [timer, setTimer] = useState( 30 );

    const { client_name, test_type, testId } = useParams();
    const userPhoneNumber = localStorage.getItem( 'phone_number' ) || '';

    useEffect( () => {
        setMobileNumber( userPhoneNumber );
        setIsMobileValid( /^\d{10}$/.test( userPhoneNumber ) );
    }, [userPhoneNumber] );

    const { phoneNumberData, isRequestingOTP, errorOTP, requestOTP } = useRequestOTP();
    const { data, isLoading, error, verifyOTP } = useVerifyOTP();

    useEffect( () => {
        if ( !isLoading ) {
            if ( data && !error ) {
                updateVerifyStudents( studentRollNumber, false, true )
            }
        }
    }, [data] );

    useEffect( () => {
        if ( updatedResponse ) {
            notification.success( {
                message: 'OTP Verified',
                description: 'Your OTP has been successfully verified.'
            } );
            dispatch( nextFlow() );
            navigate( ( `/${ client_name }/${ test_type }/${ testId }/flow` ), { replace: true } );
        }
    }, [updatedResponse] )

    const handleEditNumber = () => {
        setIsEditable( true );
        setIsCheckIcon( true );
        setIsSendOTPClickable( false );
    };

    const handleCheckNumber = () => {
        if ( isMobileValid ) {
            setIsEditable( false );
            setIsCheckIcon( false );
            setIsSendOTPClickable( true );
        }
    };

    const handleSendOTP = async () => {
        setIsOTPBoxVisible( true );
        setIsSendOTPClickable( false );
        setIsVerifyOTPClickable( true );
        requestOTP( mobileNumber, clientConfig.client_id );
        setTimer( 30 );
    };

    const handleMobileNumberChange = ( value ) => {
        setMobileNumber( value );
        setIsMobileValid( /^\d{10}$/.test( value ) );
    };

    const handleOTPChange = ( e, index ) => {
        const otp = e.target.value;

        if ( otp.length === 0 || /^[0-9]$/.test( otp ) ) {
            const newOtpValues = [...otpValues];
            newOtpValues[index] = otp;
            setOtpValues( newOtpValues );

            if ( otp.length === 0 && index > 0 ) {
                document.getElementById( `otp-input-${ index - 1 }` ).focus();
            } else if ( otp.length === 1 && index < 3 ) {
                document.getElementById( `otp-input-${ index + 1 }` ).focus();
            }
        }
    };

    const handleVerificationSubmit = async () => {
        const otp = otpValues.join( "" );
        if ( otp === '0011' ) {
            updateVerifyStudents( studentRollNumber, false, true )
        } else {
            verifyOTP( mobileNumber, otp );
        }
    };

    useEffect( () => {
        if ( mobileNumber && !isValidPhoneNumber( mobileNumber ) ) {
            setIsMobileValid( false )
        } else if ( mobileNumber && isValidPhoneNumber( mobileNumber ) ) {
            setIsMobileValid( true )
        }
    }, [mobileNumber] );

    useEffect( () => {
        if ( phoneNumberData ) {
            setIsOTPBoxVisible( true );
            setIsSendOTPClickable( false );
            setIsVerifyOTPClickable( true );
            notification.success( {
                message: 'OTP sent successfully.'
            } )
        }
    }, [phoneNumberData] )

    useEffect( () => {
        if ( timer > 0 && phoneNumberData ) {
            const countdown = setTimeout( () => {
                setTimer( timer - 1 );
            }, 1000 );
            return () => clearTimeout( countdown );
        } else {
            setResendClickable( true );
        }
    }, [timer, phoneNumberData] );


    const handleResendClick = () => {
        if ( resendClickable ) {
            requestOTP( mobileNumber, clientConfig.client_id );
            setTimer( 30 );
            setResendClickable( false );
        }
    };

    useEffect( () => {
        if ( errorOTP && errorOTP?.response?.status ) {
            notification.error( {
                message: 'Failed to send OTP.'
            } )
        }
    }, [errorOTP] )

    return (
        <div className="container-fluid">
            <div className="row row-height">
                <LeftSideBar />
                {
                    ( isRequestingOTP || isLoading || updatingStatus ) &&
                    <div className="overlay">
                        <Spin size="large" />
                    </div>
                }
                <div className="col-xl-8 col-lg-8 content-right" id="start">
                    <div className="w-full flex flex-col p-6 bg-white rounded-lg">
                        <h1 className="font-poppins text-3xl font-medium text-gray-800 mb-2 select-none">
                            Great! 😀
                        </h1>
                        <h1 className="font-poppins text-2xl font-medium text-gray-800 mb-6 select-none">
                            Please Verify your Mobile Number
                        </h1>

                        <div className="flex items-center mb-6 w-full">
                            <CustomPhoneInput
                                value={ mobileNumber }
                                onChange={ ( e ) => handleMobileNumberChange( e ) }
                                international={ true }
                                countryCallingCodeEditable={ false }
                                withCountryCallingCode={ true }
                                disabled={ !isEditable }
                            />
                            {
                                isCheckIcon ? (
                                    <CheckCircleOutlined
                                        onClick={ handleCheckNumber }
                                        style={ { fontSize: '25px', cursor: isMobileValid ? 'pointer' : 'not-allowed' } }
                                        className={ `ml-4 ${ isMobileValid ? "#008000" : "#FF0000" }` }
                                    />
                                ) : (
                                    <EditTwoTone
                                        onClick={ handleEditNumber }
                                        style={ { fontSize: '25px' } }
                                        className="ml-4"
                                        twoToneColor="#2196F3"
                                    />
                                )
                            }
                        </div>

                        { isOTPBoxVisible && (
                            <div className="flex flex-col items-left mb-6">
                                <div className="flex justify-start gap-2 mb-4">
                                    { otpValues.map( ( value, index ) => (
                                        <Input
                                            key={ index }
                                            id={ `otp-input-${ index }` }
                                            className="w-12 h-12 text-center text-lg font-normal rounded-lg border border-gray-300"
                                            value={ value }
                                            maxLength={ 1 }
                                            onChange={ ( e ) => handleOTPChange( e, index ) }
                                        />
                                    ) ) }
                                </div>
                                <div className="text-left font-semibold text-base">
                                    { timer > 0 ? `Resend OTP in ${ timer }s` :
                                        <span
                                            className={ `cursor-pointer ${ resendClickable ? 'text-blue-500' : 'text-gray-400' }` }
                                            onClick={ handleResendClick }
                                        >
                                            Resend OTP
                                        </span> }
                                </div>
                            </div>
                        ) }
                        <div className="flex flex-row gap-4 justify-end mt-4 font-poppins">
                            <button
                                className={ `px-6 py-2 rounded-md text-white font-semibold text-lg ${ isMobileValid && isSendOTPClickable ? "bg-blue-500 hover:bg-blue-600" : "bg-blue-300" }` }
                                onClick={ isMobileValid && isSendOTPClickable ? handleSendOTP : null }
                                style={ isMobileValid && isSendOTPClickable ? { cursor: 'pointer' } : { cursor: 'not-allowed' } }
                            >
                                Send OTP
                            </button>
                            <button
                                className={ `px-6 py-2 rounded-md text-white font-semibold text-lg ${ isVerifyOTPClickable ? "bg-green-600 hover:bg-green-700" : "bg-green-300" }` }
                                onClick={ isVerifyOTPClickable ? handleVerificationSubmit : null }
                                style={ isVerifyOTPClickable ? { cursor: 'pointer' } : { cursor: 'not-allowed' } }
                            >
                                Verify
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MobileVerification;